<template>
  <section
  id="homeBanner"
  >
    <router-link to="/products" class="">
      <img class="w-100" src="@/assets/images/homeBanner.jpg" alt="" />
    </router-link>
  </section>
</template>

<script>
export default {};
</script>

