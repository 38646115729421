<template>
  <div class="big-title">
    <div class="big-title-text">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BigTitle",
  props: {
    title: String
  }
}
</script>

<style scoped>
.big-title {
  font-weight: bold;
  font-size: 2.3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../../../../assets/images/other/product.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.big-title-text {
  padding-top: 15px;
  color: var(--newTheme-color);
  font-weight: bolder;
  text-align: center;
}
</style>
