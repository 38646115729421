<template>
  <div
    class="productFilterModal in-products-filter modal fade mobile-friendly"
    id="productFilterModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
           <div class="d-flex justify-content-center pt-2">
             <ZFilterSvg />
             <h6 class="mr-2  weight-bold text-color-444">فیلتر جستجو</h6>
                </div>
        </div>
        <div class="modal-body  pb-sm-5 pt-0 pb-3 px-0">
          <form @submit.prevent="productsFilter" class="mb-5">
            <div class="welog-list-side">
              <section
                class="products-list-filter box-shaddow20 px-3 p-sm-3 bg-white mb-3"
              >


                <section class=" p-3 mb-3">
                  <h6 class="mr-2 fontsize15 weight-bold text-color-444 mt-3">
                    عنوان
                  </h6>
                  <input
                    type="text"
                    v-model="filter.title"
                    placeholder="عنوان جستجو را بنویسید"
                    class=" py-2 px-3 border   w-100 fontsize14"
                  />
                </section>
                <section
                  v-if="sizeFilters && sizeFilters.length"
                  class="  box-shaddow20 p-3 bg-white mb-3"
                >
                  <div class="text-right border-bottom mb-3">
                    <h6 class="mr-2 fontsize15 weight-bold text-color-444 mt-3">
                      سایز
                    </h6>
                  </div>
                  <div class="d-flex flex-wrap">
                    <div
                      class="size-item"
                      v-for="(size, index) in sizeFilters"
                      :key="index"
                    >
                      <label
                        class="text-color-666 pr-2 d-flex align-items-center"
                        v-if="size"
                        style="line-height:1.9"
                      >
                        <span class="fontsize-small pl-1">{{ size.value }}</span>
                        <b-form-checkbox
                          v-model="filter.selected_sizes[index]"
                          class="size-label"
                          :unchecked-value="false"
                        />
                      </label>
                      <b-skeleton
                        v-else
                        class="ml-2 d-inline-block size-skeleton"
                      />
                    </div>
                  </div>
                </section>
                <div class="mt-3 py-2 d-flex">
                  <label class="switch">
                    <input
                      type="checkbox"
                      name="x1"
                      v-model="filter.available"
                    />
                    <span class="slider round"></span>
                  </label>
                  <span class="fontsize13 text-color-666 mr-2">
                    فقط کالاهای موجود
                  </span>
                </div>
              </section>
              <section class=" box-shaddow20 p-3 bg-white mb-3">
                <h6 class="mr-2 fontsize15 weight-bold text-color-444">
                  قیمت ها
                </h6>
                <vue-slider
                  class=" mx-2 my-4"
                  ref="slider"
                  v-model="priceRange"
                  :tooltip="'none'"
                  :max="minMax"
                  :interval="step"
                  direction="rtl"
                >
                </vue-slider>
                <div
                  class="fontsize13 d-flex flex-wrap justify-content-between"
                >
                  <div class="ml-3">
                    <span class="text-color-444 ml-1">از قیمت:</span>
                    <span class="text-color-666" v-if="priceRange[0] != 0"
                      >{{ priceRange[0] | price }} تومان</span
                    >
                    <span class="text-color-666" v-else>0 تومان</span>
                  </div>
                  <div>
                    <span class="text-color-444 ml-1">تا</span>
                    <span class="text-color-666"
                      >{{ priceRange[1] | price }} تومان</span
                    >
                  </div>
                </div>
              </section>
              <div class="px-3">
                <button
                  type="submit"
                  :disabled="disabled"
                  class="fontsize15 weight-bold text-white bg-color-theme py-2 w-100  "
                >
                  اعمال فیلتر
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import closeModalMixin from '@/libraries/mixins/closeModalMixin'
import {BSkeleton, BFormCheckbox} from 'bootstrap-vue'
import ZFilterSvg from "@/parts/Front/components/shared/ZFilterSvg";
import VueSlider from 'vue-slider-component'

export default {
  components: {
    ZFilterSvg,
    BSkeleton, BFormCheckbox,
    'vue-slider':VueSlider
  },
  props: {
    value: Object,
    loading: {
      default: false,
      type: Boolean,
    },
  },
  mixins: [closeModalMixin],
  data() {
    return {
      // filter:{
      //     title:'',
      //     available: 0,
      //     max_price:'',
      //     min_price:'',
      // },
      // disabled: false
      modals: ['productFilterModal'],
      priceRange: [0, 2000000],
      step: 1000,
      filter: cloneDeep(this.value),
    };
  },
  watch: {
    value: {
      handler: function(newVal) {
        this.filter = cloneDeep(newVal);
      },
      deep: true,
    },
    minMax(newVal) {
      this.$set(this.priceRange, 1, newVal);
    },
  },
  computed: {
    disabled() {
      return this.$store.getters["front/getProductsStatus"];
    },
    minMax() {
      return this.$store.getters["front/getProductsMaxPrice"];
    },
    sizeFilters() {
      return this.$store.getters["front/getSizeValues"]
        ? this.$store.getters["front/getSizeValues"].values
        : Array(6).fill(null);
    },
  },
  created() {
    if (this.minMax) {
      this.priceRange = [0, this.minMax];
    }
  },
  methods: {
    productsFilter() {
      this.filter.page = 1;
      this.filter.max_price = this.priceRange[1];
      this.filter.min_price = this.priceRange[0];
      this.filter.available
        ? (this.filter.available = 1)
        : (this.filter.available = 0);
      this.$emit("input", this.filter);
      window.$("#productFilterModal").modal("hide");
    },
  },
};
</script>
<style scoped>
.size-label {
  display: inline;
  margin-right: 2px;
}

.size-item {
  display: inline;
  margin-left: 5px;
}

.size-skeleton {
  width: 35px;
}

.color-option {
  background: black;
}
.color-option-img {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: bottom;
  margin-left: 5px;
}
</style>
<style>
.vue-slider-dot {
  cursor: pointer;
}

.in-products-filter
  .custom-control-input:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.13rem rgb(222 222 222);
}

.in-products-filter
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #a07f5c;
  background-color: #121314;
}
.in-products-filter .modal-header  {
  justify-content: center;
border-bottom: 1px solid #dee2e6;
}
.in-products-filter .modal-header .close {
  position: absolute;
right: 20px;
top: 15px;
margin: -1rem -1rem -1rem 0;
}
</style>
