<template>
  <router-link :to="item.to ? item.to : ''" class="category__box">
    <div class="title">
      <span class="line"></span>
      <span class="text">{{ item.title }}</span>
    </div>
    <div class="category__box__image">
      <img :src="item.image.url" alt="category" />
    </div>
  </router-link>
</template>

<script>
export default {
  name: "CategoryCard",
  props: {
    item: Object
  }
}
</script>

<style scoped>
.category__box .title {
  position: absolute;
  left: -120px;
  top: -80px;
  display: block;
  transform: rotate(-90deg);
  z-index: 2;
  width: 200px;
  height: 20px;
  text-align: left;
  color: #7a7979;
  font-weight: 300;
}

.category__box .title .text {
  transition: 200ms all;
  position: absolute;
  left: -102px;
  bottom: 5px;
  width: 200px;
  height: 20px;
  font-size: 20px;
}




.category__box .title .line {
  height: 130px;
  width: 1px;
  position: absolute;
  left: -39px;
  top: -36px;
  background-color: #a4aaaf;
  transform: rotate(-90deg);

}


.category__box {
  position: relative;
  width: 25%;
  padding: 1rem;
  padding-top: 0;
}

.category__box__image {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 10px;
  --transition-duration: 1s;
  --transition-easing: ease;
  transition: transform var(--transition-duration) var(--transition-easing);
}

.category__box__image::before {
  content: "";
  right: -50%;
}

.category__box__image:hover::before {
  background: rgba(255, 255, 255, 0.4);
  width: 25%;
  height: calc(100% - 15px);
  top: 50%;
  transform: translateY(calc(-50% - 2px));
  /* transform: skew(45deg); */
  position: absolute;
  transition: right var(--transition-duration) var(--transition-easing);
  right: 150%;
}

/* .category__box:nth-child(odd):hover img {
  backdrop-filter: blur(2px);
  transform: scale(2)  rotate(25deg);
  overflow: hidden;
}
.category__box:nth-child(even):hover img {
  backdrop-filter: blur(2px);
  transform: scale(2)  rotate(-25deg);
  overflow: hidden;
} */

.category__box__image img {
  box-shadow: 1px 3px 7px #999;
  width: 100%;
  border-radius: 10px;
  transition: all 1s ease;
}

@media only screen and (max-width: 992px) {
  .category__box:first-child {
    display: none;
  }

  .category__box .title {
    display: none;
  }

  .category__box:nth-child(odd) {
    margin-top: 0px!important;
  }

  .category__box {
    /* margin: 10px; */
    width: 50%;
    padding: 5px;
  }
}


@media only screen and (max-width: 430px) {
  .category__box {
    padding: 5px;
  }
}

.category__box:hover  .title .text {
  left: -60px!important;
}

.category__box:nth-child(odd) {
  margin-top: 90px;
}
</style>
