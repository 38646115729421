<template>
  <button
      @click="(e) => $emit('click', e)"
      type="button"
      id="all-categories"
      class="all-categories resp-menu-btn rounded py-1 px-2 d-lg-none"
      style="background: transparent"
  >
    <svg
        width="30px"
        height="30px"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
      <rect
          fill="currentColor"
          width="24"
          height="24"
          transform="rotate(180 12 12)"
          opacity="0"
      />
      <circle fill="currentColor" cx="4" cy="12" r="1" />
      <rect
          fill="#000"
          x="7"
          y="11"
          width="14"
          height="2"
          rx=".94"
          ry=".94"
      />
      <rect
          fill="currentColor"
          x="3"
          y="16"
          width="18"
          height="2"
          rx=".94"
          ry=".94"
      />
      <rect
          fill="currentColor"
          x="3"
          y="6"
          width="18"
          height="2"
          rx=".94"
          ry=".94"
      />
    </svg>
  </button>

</template>

<script>
export default {
  name: "NavButton"
}
</script>

<style scoped>

</style>
