<template>
  <div>
    <WhatsappContact />
    <div >
        <Header v-if="$route.path != '/'" />
        <router-view :key="$route.path"></router-view>
        <Footer />
        <FooterButtons></FooterButtons>
    </div>
    <div id="mainSpinner" v-show="status">
      <div v-if="status" class="flower-spinner">
        <div class="dots-container">
          <div class="bigger-dot">
            <div class="smaller-dot"></div>
          </div>
        </div>
      </div>
    </div>
    <Compare @entered="showCompareContent=true"/>
    <transition name="fade">
      <CompareContent @left="showCompareContent=false" v-if="!mediaQueries.mobileSize && Boolean(compares.length) && showCompareContent"/>
    </transition>
  </div>
</template>
<script>
import "bootstrap";
import Header from "@/parts/Front/components/shared/HeaderSecond";
import Footer from "@/parts/Front/components/shared/Footer";
import FooterButtons from "@/parts/Front/components/FooterButtons";
import Compare from "@/parts/Front/components/shared/Compare";
import CompareContent from "@/parts/Front/components/shared/CompareContent";
import WhatsappContact from "../components/WhatsappContact";


//   در هر کامپوننت میتوانیم از این متغییر استفاده کنیم نحوه استفاده در کامپوننت زیر
// RespLoginModal
export default {
  name: "Home",
  components: {
    WhatsappContact,
    Header,
    Footer,
    FooterButtons,
    Compare,
    CompareContent
  },
  inject: ['mediaQueries'],
  data() {
    return {
      showCompareContent: false
    }
  },
  computed: {
    status() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
    compares() {
      console.log(this.$store.getters['front/getCompares'])
      return this.$store.getters['front/getCompares'];
    }
  },
  created() {
    this.$store.dispatch("front/getHomeDataFromServer", {
      $root: this.$root
    });
  },
};
</script>
<style scoped>
/* اسپینر استایل */
#mainSpinner{
    position: fixed;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999999999;
  background: rgba(138, 114, 114, 0.72);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flower-spinner,  .flower-spinner * {
  box-sizing: border-box;
}

.flower-spinner {
  height: 70px;
  width: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flower-spinner .dots-container {
  height: calc(70px / 7);
  width: calc(70px / 7);
}

.flower-spinner .smaller-dot {
  background: var(--color-theme);
  height: 100%;
  width: 100%;
  border-radius: 50%;
  animation: flower-spinner-smaller-dot-animation 2.5s 0s infinite both;

}

.flower-spinner .bigger-dot {
  background: var(--color-theme);
  height: 100%;
  width: 100%;
  padding: 10%;
  border-radius: 50%;
  animation: flower-spinner-bigger-dot-animation 2.5s 0s infinite both;
}

@keyframes flower-spinner-bigger-dot-animation {
  0%, 100% {
    box-shadow: var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px;
  }

  50% {
    transform: rotate(180deg);
  }

  25%, 75% {
    box-shadow: var(--color-theme) 26px 0px 0px,
    var(--color-theme) -26px 0px 0px,
    var(--color-theme) 0px 26px 0px,
    var(--color-theme) 0px -26px 0px,
    var(--color-theme) 19px -19px 0px,
    var(--color-theme) 19px 19px 0px,
    var(--color-theme) -19px -19px 0px,
    var(--color-theme) -19px 19px 0px;
  }

  100% {
    transform: rotate(360deg);
    box-shadow: var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px;
  }
}

@keyframes flower-spinner-smaller-dot-animation {
  0%, 100% {
    box-shadow: var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px;
  }

  25%, 75% {
    box-shadow: var(--color-theme) 14px 0px 0px,
    var(--color-theme) -14px 0px 0px,
    var(--color-theme) 0px 14px 0px,
    var(--color-theme) 0px -14px 0px,
    var(--color-theme) 10px -10px 0px,
    var(--color-theme) 10px 10px 0px,
    var(--color-theme) -10px -10px 0px,
    var(--color-theme) -10px 10px 0px;
  }

  100% {
    box-shadow: var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px,
    var(--color-theme) 0px 0px 0px;
  }
}
</style>
