<template>
  <header class="">
    <section
      class="
        container
        d-flex
        justify-content-between
        align-items-baseline
        py-3
      "
    >
      <NavButton @click="allCategoriesClose()" />

      <div class="d-flex align-items-center" style="gap: 30px">
        <AtlasLogo />
        <Nav></Nav>
      </div>


      <div class="">

        <SearchButton class="d-md-inline-block d-none bg-none ml-2"/>

        <!-- اگر لاگین نبود ایکن ورود نمایش داده شود -->
        <button
          v-if="!isLogin"
          type="button"
          class="d-md-inline-block d-none bg-none ml-2"
          data-toggle="modal"
          data-target="#loginModal"
        >
          <!-- <svg
            width="27"
            height="27"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="11.5789"
              cy="7.27803"
              r="4.77803"
              stroke="#444"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.00002 18.7014C3.99873 18.3655 4.07385 18.0337 4.2197 17.7311C4.67736 16.8158 5.96798 16.3307 7.03892 16.111C7.81128 15.9462 8.59431 15.836 9.38217 15.7815C10.8408 15.6533 12.3079 15.6533 13.7666 15.7815C14.5544 15.8367 15.3374 15.9468 16.1099 16.111C17.1808 16.3307 18.4714 16.77 18.9291 17.7311C19.2224 18.3479 19.2224 19.064 18.9291 19.6808C18.4714 20.6419 17.1808 21.0812 16.1099 21.2918C15.3384 21.4634 14.5551 21.5766 13.7666 21.6304C12.5794 21.7311 11.3866 21.7494 10.1968 21.6854C9.92221 21.6854 9.65677 21.6854 9.38217 21.6304C8.59663 21.5773 7.81632 21.4641 7.04807 21.2918C5.96798 21.0812 4.68652 20.6419 4.2197 19.6808C4.0746 19.3747 3.99955 19.0401 4.00002 18.7014Z"
              stroke="#444"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg> -->
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 12.5537C12.2546 12.5537 14.4626 10.3171 14.4626 7.52684C14.4626 4.73663 12.2546 2.5 9.5 2.5C6.74543 2.5 4.53737 4.73663 4.53737 7.52684C4.53737 10.3171 6.74543 12.5537 9.5 12.5537ZM9.5 15.0152C5.45422 15.0152 2 15.6621 2 18.2464C2 20.8298 5.4332 21.5 9.5 21.5C13.5448 21.5 17 20.8531 17 18.2687C17 15.6844 13.5668 15.0152 9.5 15.0152ZM19.8979 9.58786H21.101C21.5962 9.58786 22 9.99731 22 10.4995C22 11.0016 21.5962 11.4111 21.101 11.4111H19.8979V12.5884C19.8979 13.0906 19.4952 13.5 18.999 13.5C18.5038 13.5 18.1 13.0906 18.1 12.5884V11.4111H16.899C16.4027 11.4111 16 11.0016 16 10.4995C16 9.99731 16.4027 9.58786 16.899 9.58786H18.1V8.41162C18.1 7.90945 18.5038 7.5 18.999 7.5C19.4952 7.5 19.8979 7.90945 19.8979 8.41162V9.58786Z" fill="#200E32"></path></svg>
        </button>
      <div class="d-inline-block" :class="notifications && notifications.items && notifications.items.length ? 'ml-4' : 'ml-1'">
        <CartButton />
        <HeaderNotification v-if="notifications" v-show="notifications && notifications.items && notifications.items.length"
                            :value="notifications" />
          <!-- لاگین بود نمایش حساب کاربری -->

        <SearchButton class="bg-none ml-2 d-md-none"/>
      </div>
        <template v-if="isLogin">
          <LoginUser :atlasTheme="false" />
        </template>
      </div>

    </section>
    <MainSearch></MainSearch>
    <!-- resp login Modal -->
    <RespLoginModal></RespLoginModal>
    <CartLists></CartLists>
    <HeaderBanner class="d-none d-md-block"/>
     <!-- <b-skeleton-img v-if="status"></b-skeleton-img> -->
    <!--<section v-else class="top-slider position-relative">
      <div class="swiper-container h-100">
        <div v-if="homeData" class="swiper-wrapper">
          <div
            v-for="slider in sliders"
            :key="slider.id"
            class="swiper-slide bg-color-ea"
          >
            <div v-if="slider.description"
              class="
                top-slider-content
                d-flex
                justify-content-between
                container
                mx-auto
              "
            >
              <div class="text-right my-auto ml-3">
                <span
                  class="
                    top-header-discount
                    bg-color-themeRed
                    fontsize-mini
                    text-white
                    px-2
                  "
                  v-if="slider.custom_fields && slider.custom_fields.discount"
                  >{{ slider.custom_fields.discount }}</span
                >
                <p class="mb-2 fontsize-xheavy text-color-666" style="font-family: yekan-bakh-bold"
                   v-if="slider.custom_fields && slider.custom_fields.short_description">
                  {{ slider.custom_fields.short_description }}
                </p>
                <h5 class="font-weight-bold mb-0 text-black fontsize-fat">
                  {{ slider.title }}
                </h5>
                <p
                  class="
                    fontsize-medium
                    text-justify text-color-444
                    line-height2
                    d-lg-block d-none
                  "
                >
                  {{ slider.description }}
                </p>
                <div> -->
<!--                  <router-link-->
<!--                    v-if="slider.linkable_type != null"-->
<!--                    :to="findUrl(slider.linkable_type, slider.linkable_id)"-->
<!--                  >-->
<!--                    <button-->
<!--                      type="button"-->
<!--                      class="-->
<!--                        top-slider-cart-->
<!--                        text-black-->
<!--                        bg-color-theme-->
<!--                        fontsize-medium-->
<!--                        weight-bold-->
<!--                        py-md-2 py-1-->
<!--                        px-md-3 px-2-->
<!--                        ml-md-3 ml-2-->
<!--                      "-->
<!--                    >-->
<!--                      خرید این محصول-->
<!--                    </button>-->
<!--                  </router-link>-->
<!--                  <a v-else :href="slider.link">-->
<!--                    <button-->
<!--                      type="button"-->
<!--                      class="-->
<!--                        top-slider-cart-->
<!--                        text-black-->
<!--                        bg-color-theme-->
<!--                        fontsize-medium-->
<!--                        weight-bold-->
<!--                        py-md-2 py-1-->
<!--                        px-md-3 px-2-->
<!--                        ml-md-3 ml-2-->
<!--                      "-->
<!--                    >-->
<!--                      خرید این محصول-->
<!--                    </button>-->
<!--                  </a>-->

                  <!-- <router-link
                    v-if="slider.linkable_type != null"
                    :to="findUrl(slider.linkable_type, slider.linkable_id)"
                  >
                    <button
                      type="button"
                      class="
                        top-slider-more
                        d-md-inline-block d-none
                        text-color-666
                        fontsize-medium
                        py-md-2 py-1
                        px-md-3 px-2
                        bg-none
                      "
                    >
                      اطلاعات بیشتر ...
                    </button>
                  </router-link>

                  <a v-else :href="slider.link">
                    <button
                      type="button"
                      class="
                        top-slider-more
                        d-md-inline-block d-none
                        text-color-666
                        fontsize-mini
                        py-md-2 py-1
                        px-md-3 px-2
                        bg-none
                      "
                    >
                      اطلاعات بیشتر ...
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <DynamicLinker class="top-slider-img" :item="slider">
              <img
                  :src="slider.image.url"
                  class="w-100 h-100"
                  :alt="slider.title"
              />
            </DynamicLinker>
          </div>
        </div>
        <div class="d-md-none swiper-pagination"></div>
      </div>
      <div class="d-md-block d-none swiper-button-next text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          class="bi bi-chevron-compact-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
            fill="#999"
          />
        </svg>
      </div>
      <div class="d-md-block d-none swiper-button-prev">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          class="bi bi-chevron-compact-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
            fill="#999"
          />
        </svg>
      </div>
    </section> -->
    <RespMenu ref="respMenu"></RespMenu>
    <!--    <div class="all-categories-resp" @click="allCategoriesCloseBody()"></div> &lt;!&ndash; close menu by click to body&ndash;&gt;-->
  </header>
</template>

<script>
import AtlasLogo from '@/parts/Front/components/AtlasLogo'
import Swiper from "swiper/swiper-bundle.min.js";
import Nav from "@/parts/Front/components/Nav";
import ProductCategories from "@/parts/Front/components/ProductCategories";
import RespMenu from "@/parts/Front/components/RespMenu";
import MainSearch from "@/parts/Front/components/MainSearch";
import LoginUser from "@/parts/Front/components/LoginUser";
import RespLoginModal from "@/parts/Front/components/RespLoginModal";
import CartLists from "@/parts/Front/components/CartLists";
import findUrl from "@/libraries/links/LinkResolver";
import HeaderNotification from "@/parts/Front/components/notification/HeaderNotification";
import CartButton from "@/parts/Front/components/cart/CartButton"
import DynamicLinker from "./DynamicLinker";
import SearchButton from "../header/SearchButton";
import {BSkeletonImg} from 'bootstrap-vue'
import NavButton from "@/parts/Front/components/shared/NavButton";
import HeaderBanner from "@/parts/Front/components/header/HeaderBanner.vue"
export default {
  name: "Header",
  components: {
    NavButton,
    SearchButton,
    DynamicLinker,
    AtlasLogo,
    HeaderNotification,
    Nav,
    LoginUser,
    ProductCategories,
    RespMenu,
    MainSearch,
    RespLoginModal,
    CartLists,
    CartButton,
    BSkeletonImg,
    HeaderBanner
  },
  watch: {
    homeData() {
      // پاک کردن سویپر برای نمایش درست اسلایدر
      this.initHeaderSwiper(true);
    },

  },
  inject:['mediaQueries'],
  methods: {
    findUrl,
    getSliderCustomFields(slider) {
      return JSON.parse(slider.custom_fields)
    },
    allCategoriesClose() {
      this.$refs.respMenu.allCategoriesToggle()
    },
    onResize() {
      let topSliderH = document.querySelector(".top-slider");
      let bodyWidth = document.body.offsetWidth;
      if (topSliderH) {
        if (bodyWidth < 767) {
          topSliderH.style.height = bodyWidth / 1.6 + "px";
        } else {
          topSliderH.style.height = bodyWidth / 1.74 + "px";
        }
      }
    },
    initHeaderSwiper(kill = false) {
      this.initSwiper("header-swiper", kill, () => {
        try {
          if (this.slider) {
            this.slider.destroy()
          }
        } catch (e) {}

        this.slider = new Swiper(".top-slider .swiper-container", {
          spaceBetween: 0,
          //   centeredSlides: true,
          speed: 1000,
          loop: false,
          autoplay: {
            delay: 4500,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".top-slider .swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".top-slider .swiper-button-next",
            prevEl: ".top-slider .swiper-button-prev",
          },
        });
      });
      this.$nextTick(() => {
        this.onResize()
      })
    },
    signout() {
      window
        .swal({
          title: "آیا میخواهید خارج شوید؟",

          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله خارج شو"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.disabledlLogout=true;
            this.$axios.post("/customer/logout").then((res) => {
              this.disabledlLogout=false;
              this.showOptions = false;
              // پاک کردن اطلاعات مربوط به کاربر از کوکی و ویو ایکس
              delete this.$axios.defaults.headers.common["Authorization"]
              this.$root.deleteCookie("Authorization");
              this.$store.commit("front/setLoginStatus", false);
            });
          }
        });
    },
  },
  mounted() {
    // ست کردن تنظیمات  برای اسکرول صفحه در برابر رسپانسیو
    window.removeEventListener("resize", this.onResize);
    window.addEventListener("resize", this.onResize);
    this.initHeaderSwiper();
    //CHange height of top-slider
    const topSliderH = document.querySelector(".top-slider");
    const bodyWidth = document.body.offsetWidth;
    if (topSliderH) {
      if (bodyWidth < 767) {
        topSliderH.style.height = bodyWidth / 1.6 + "px";
      } else {
        topSliderH.style.height = bodyWidth / 1.74 + "px";
      }
    }

    window.removeEventListener('resize', this.onResize);
    window.addEventListener("resize", this.onResize);
  },
  computed: {
    // دریافت دیتا از سمت ویوایکس
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
    sliders() {
      let sliders;
      if (this.mediaQueries.medium) {
        sliders = this.homeData.sliders.filter(s => s.group.includes('mobile'));
      } else {
        sliders = this.homeData.sliders.filter(s => !s.group.includes('mobile'));
      }
      sliders.forEach(slider => {
        try {
          slider.custom_fields = this.getSliderCustomFields(slider);
        }catch (e) {}
      })

      return sliders
    },
    // چک کردن لاگین بودن کاربر با استفاده از ویو ایکس در سند یوزر
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
    // دریافت اطلاعات کاربر
    FullName() {
      return this.$store.getters["front/getFullName"];
    },
    status() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
    notifications() {
      return this.$store.getters["front/getNotifications"];
    }
  },

  data() {
    return {
      disabledlLogout:false
    };
  },
};
</script>

<style scoped>

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>
