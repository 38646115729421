<template>
  <div v-if="withButtons && mediaQueries" class="whatsapp-contact" :class="{'whatsapp-hidden': !show}">
    <a :href="social.whatsapp">
      <img width="80" height="80" src="@/assets/images/whatsapp.png" alt="واتساپ">
    </a>
  </div>
</template>

<script>
export default {
  name: "WhatsappContact",
  inject:['mediaQueries'],
  watch: {
    $route() {
      this.$nextTick(() => {
        this.init()
      })
    }
  },
  computed: {
    social() {
      let homeData = this.$store.getters['front/getHomeData'];

      return homeData ? homeData.settings.social : {}
    }
  },
  data () {
    return {
      show: true,
      lastScrollPosition: 0,
      withButtons: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    init() {
      this.withButtons = Boolean(document.querySelector('.footer-buttons'));
    },
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return
      }
      // Here we determine whether we need to show or hide the navbar
      this.show = currentScrollPosition >100
      // Set the current scroll position as the last scroll position
      this.lastScrollPosition = currentScrollPosition
    }
  }
}
</script>

<style scoped>
.whatsapp-contact {
  position: fixed;
  bottom: 65px;
  right: 10px;
  z-index: 9;
}


.whatsapp-hidden {
  transform: translate3d(0, 100%, 0);
}

@media screen and (min-width: 768px) {
  .whatsapp-contact {
    bottom: 10px;
  }

  .whatsapp-hidden {
    transform: translate3d(0, 105%, 0);
  }
}

</style>
