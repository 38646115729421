<template>
  <button
      type="button"
      @click="showCloseSearchBox()"
  >
    <!-- <svg
        width="27"
        height="27"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <circle
          cx="11.7666"
          cy="11.7666"
          r="8.98856"
          stroke="#444"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
      />
      <path
          d="M18.0183 18.4851L21.5423 22"
          stroke="#444"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
      />
    </svg> -->
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="11.7666" cy="11.7666" r="8.98856" stroke="#200E32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle><path d="M18.0183 18.4851L21.5423 22" stroke="#200E32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
  </button>

</template>

<script>
import {openSearch} from "../../../../libraries/functions";

export default {
  name: "SearchButton",
  methods: {
    showCloseSearchBox() {
      openSearch()
    },
  }
}
</script>

<style scoped>

</style>
