<template>
  <section
    class=" d-flex justify-content-center flex-md-row flex-column align-items-center container mt-2 mt-sm-5 mb-sm-5 mb-4"
    style="gap:15px;"
  >
    <div>
      <img class="w-100" src="@/assets/images/right-baner.png" alt="" />
    </div>
    <div><img class="w-100" src="@/assets/images/left-baner.png" alt="" /></div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
