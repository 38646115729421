<template>
  <router-link
      :to="to"
      class="recommended-products-more fontsize-mini text-black px-3 py-3 mt-3 d-inline-block"
  >
    <span :class="{'ml-3': !mediaQueries.mobile}"> {{title}} </span>
  <ArrowLeft class="icon"/>
  </router-link>
</template>

<script>
import ArrowLeft from "@/parts/Front/nicecomponents/svgs/ArrowLeft";
export default {
  name: "MoreButton",
  inject: ['mediaQueries'],
  components: {ArrowLeft},
  props: {
    to: String,
    title: {
      type: String,
      default: "مشاهده همه محصولات"
    }
  }
}
</script>

<style scoped>
.icon {
  transition: 200ms all;
}
.recommended-products-more:hover .icon {
  transform: translateX(3px);
}
.recommended-products-more {
  background: var(--newTheme-color);
  color: white;
  border-radius: 5px;
}

.recommended-products-more:hover {
  background:#422111;
  color: white;
}

</style>
