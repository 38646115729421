<template>
  <div class="in-product-detail">
    <main class="main product-details-main">
      <PagesPath :pathTitle1="'محصولات'" :pathTo1="'/products'"
                 :pathTitle2="product != null ? product.title : ''"></PagesPath>
      <ProductContent v-model="product"/>
<!--      <ProductServices/>-->
      <section
          class="product-specifications-comments container bg-white  text-right box-shaddow20 mb-4 mb-sm-5">
        <ul v-if="product != null" class="nav nav-tabs border-bottom py-4 px-1">
          <li v-if=" product != null && product.description != null"
              :class="activeTab === 'review' ? 'active' : ''"><a
              class="px-sm-3 p-2 m-1 text-color-444 fontsize16 font-weight-bold" data-toggle="tab"
              :class="activeTab==='review' ? 'active' : ''" @click="changeTab('review')">
            نقد بررسی
          </a></li>
          <li v-if="product != null && product.specifications != '' "
              :class="activeTab === 'specifications' ? 'active' : '' "><a
              class="px-sm-3 p-2 m-1 text-color-444 fontsize16 font-weight-bold" data-toggle="tab"
              :class="activeTab === 'specifications' ? 'active' : '' " @click="changeTab('specifications')">
            مشخصات
          </a></li>
          <li :class="activeTab === 'comments' ? 'active' : '' "><a
              class="px-sm-3 p-2 m-1 text-color-444 fontsize16 font-weight-bold" data-toggle="tab"
              :class="activeTab === 'comments' ? 'active' : '' " @click="changeTab('comments')">
            نظرات کاربران
          </a></li>
        </ul>
        <ul v-else class="nav nav-tabs border-bottom py-4 px-1">
          <li class=" active"><a
              class="px-sm-3 p-2 m-1 text-color-444 fontsize14 font-weight-bold active"
              data-toggle="tab" href="#review">
            نقد بررسی
          </a></li>
        </ul>
        <div v-if="product != null" class="tab-content  ">
          <ProductReview :activeTab="activeTab" :product="product"/>
          <ProductSpecifications v-if="product != null && product.specifications != '' "
                                 :activeTab="activeTab" :product="product"/>
          <ProductComments :activeTab="activeTab" :product="product"/>
        </div>
        <div v-else class="tab-content  ">
          <div id="review" class="tab-pane fade in active show">
            <p class="fontsize14 text-color-666 text-justify line-height2 p-3">
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="85%"></b-skeleton>
            </p>
          </div>
        </div>
      </section>
      <SimilarProducts :related="relatedProducts"/>
      <ScrollToTop />
    </main>
    <Footer />
  </div>
</template>

<script>
import PagesPath from "@/parts/Front/components/PagesPath";
import ScrollToTop from "@/parts/Front/components/ScrollToTop";
import ProductServices from "@/parts/Front/components/productDetail/ProductServices";
import ProductComments from "@/parts/Front/components/productDetail/ProductComments";
import ProductSpecifications from "@/parts/Front/components/productDetail/ProductSpecifications";
import ProductReview from "@/parts/Front/components/productDetail/ProductReview";
import SimilarProducts from "@/parts/Front/components/productDetail/SimilarProducts";
import ProductContent from "@/parts/Front/components/productDetail/ProductContent";
import product from "@/parts/Front/components/productDetail/classes/product";
import ProductCongratulations from "@/parts/Front/components/productDetail/ProductCongratulations";
import config from '@/config'
import {BSkeleton} from 'bootstrap-vue'

export default {
  name: "ProductDetails",
  components: {
    ProductCongratulations,
    PagesPath,
    ScrollToTop,
    ProductServices,
    ProductComments,
    ProductSpecifications,
    ProductReview,
    SimilarProducts,
    ProductContent,
    BSkeleton
  },
  data() {
    return {
      url: 'front/products/',
      title: '',
      disabled: false,
      relatedProducts: Array(5).fill(null),
      product: null,
      activeTab: null,
    }
  },
  mounted() {
    // this.$store.dispatch('front/getProductDataFromServer', this.$route.params.id)
    if (this.$route.params.id) {
      this.getProduct(this.$route.params.id)
    }

    this.onResize()
    window.removeEventListener('resize', this.onResize)
    window.addEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      const bodyWidth = document.body.offsetWidth;
      const colorChooseImg = document.querySelectorAll('.color-choose-img')[0];
      // const productDetContent=document.querySelectorAll('.product-details-content')[0];
      if (colorChooseImg) {
        if (bodyWidth < 767) {
          colorChooseImg.style.width = (bodyWidth - 100) + "px";

        }
      }
    },
    changeTab(name) {
      // this.$store.commit('front/setProductActiveTab', name)
      this.activeTab = name
    },
    async getProduct(id) {
      this.disabled = true
      try {
        const response = await this.$axios.get(`front/products/${id}`)
        this.product = new product(response.data.data.product, this);
        this.relatedProducts = response.data.data.relatedProducts

        if (this.product.description != null) {
          this.activeTab = 'review'
        } else if (this.product.description == null && this.product.specifications != '') {
          this.activeTab = 'specifications'
        } else {
          this.activeTab = 'comments'
        }
      } catch (error) {
        console.log(error);
        this.$root.errorNotify('محصول مورد نظر یافت نشد');
        this.$router.push('/')
      } finally {
        this.disabled = false
      }
    },
  },
  metaInfo() {
    return {
      title: this.product ? config.title + ` - ${this.product.title}` : config.title,
      meta: [
        {
          name: 'description',
          content: this.product ? (this.product.meta_description ? this.product.meta_description : this.product.short_description) : false
        },
        {property: 'og:title', content: this.product ? this.product.title : false},
        {name: 'robots', content: 'index,follow'}
      ]
    }
  }
}
</script>

<style scoped>

</style>
<style>
.bg-white {
  background-color: #ffffff73!important;
}
@media screen and (max-width: 768px) {
  h2 {
    font-size: 1.6rem;
  }
}</style>
