<template>
    <div class="modal fade modal-size-chart" id="sizeModal" tabindex="-1" aria-labelledby="sizeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header text-right">
                    <button type="button" class="close ml-0" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h6 class="modal-title weight-bold text-color-444 mx-auto fontsize18" id="sizeModalLabel">راهنمای
                        انتخاب سایز
                    </h6>
                </div>
                <div class="modal-body text-center">
                  <div v-for="size in sizeChart" :key="size.id">
                    <span class="sizechart-title fontsize-medium">{{ size.title }}</span>
                    <table  class="table card-table table-vcenter text-nowrap mb-0">
                      <thead class="">
                      <tr v-if="JSON.parse(size.chart)[0]" >
                        <th v-for="(cell , index) in JSON.parse(size.chart)[0]" :key="index" class="border-bottom-0 border-top-0">
                          {{JSON.parse(size.chart)[0][index]}}
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(row , rowIndex) in JSON.parse(size.chart).slice(1)" :key="rowIndex" class="position-relative">
                        <th v-for="(cell , cellIndex) in row" :key="cellIndex" scope="row">
                          {{JSON.parse(size.chart)[rowIndex + 1][cellIndex]}}
                        </th>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props:['sizeChart'],
        data() {
            return {
                dataSizeChart:[]
            }
        },
        mounted() {
            if(this.sizeChart != null) {
                this.sizeChart.forEach(s => {
                    this.dataSizeChart.push( JSON.parse(s.chart))
                });
            }
        }
    }
</script>
<style >
    .modal-size-chart tbody tr th {
        font-weight: normal !important;
    }
</style>
<style scoped>
.sizechart-title {
  display: block;
  font-weight: bold;
  margin-top: 7px;
  text-align: right;
}
</style>
