<template>
  <section class=" mt-2 mt-sm-5 mb-sm-5 mb-4 px-5">
    <div
      class=" d-flex flex-md-row flex-column align-items-center justify-content-around images-banner "
    >
      <router-link to="/">
        <img
          class="w-100 border-radius15 "
          src="@/assets/images/banner-img-2.jpg"
          alt=""
      /></router-link>
      <router-link to="/"
        ><img
          class="w-100  border-radius15"
          src="@/assets/images/banner-img-1.jpg"
          alt=""
      /></router-link>
      <router-link to="/">
        <img
          class="w-100 border-radius15 "
          src="@/assets/images/banner-img-2.jpg"
          alt=""
      /></router-link>
      <router-link to="/"
        ><img
          class="w-100  border-radius15"
          src="@/assets/images/banner-img-1.jpg"
          alt=""
      /></router-link>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
.images-banner a {
  width: 24%;
}
@media (max-width:768px){
  .images-banner a {
  width: 100%;
  margin-top: 1rem;
}
}
</style>
