<template>
  <div class="product-warranty">
    <a class="warranty-item">
      <img src="@/assets/images/15days.svg" alt="warranty">
      <span>ضمانت بازگشت کالا</span>
    </a>
    <a class="warranty-item">
      <img src="@/assets/images/originality-light.svg" alt="originality">
      <span>ضمانت اصالت کالا</span></a>
    <a class="warranty-item">
      <img src="@/assets/images/support-light.svg" alt="support">
      <span>خدمات پس از فروش</span>
    </a>
    <a class="warranty-item">
      <img src="@/assets/images/delivery-light.svg" alt="delivery">
      <span>تحویل سریع و آسان</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "ProductWarranty"
}
</script>

<style scoped>
.product-warranty {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.product-warranty span {
  margin-right: 0.4rem;
}

.product-summary-section .product-warranty{display:-webkit-box;display:-ms-flexbox;display:flex;margin-top:57px;width:100%;}
.product-summary-section .product-warranty .warranty-item{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-ms-flex:1;flex:1;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-left:30px;}
.product-summary-section .product-warranty .warranty-item img{-webkit-transition:0.2s all;-o-transition:0.2s all;transition:0.2s all;-webkit-filter:saturate(0);filter:saturate(0);}
.product-summary-section .product-warranty .warranty-item:hover img{-webkit-filter:saturate(1);filter:saturate(1);-webkit-transform:scale(1.03, 1.03);-ms-transform:scale(1.03, 1.03);transform:scale(1.03, 1.03);}
.product-summary-section .product-warranty .warranty-item:last-child{margin-left:0;}
@media screen and (max-width: 1440px){
  .product-summary-section .product-warranty .warranty-item{margin-left:12px;}
}
.product-summary-section .product-warranty .warranty-item span{font-size:12px;color:#28292c;display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;text-align:right;margin-right:12px;}
@media screen and (max-width: 1440px){
  .product-summary-section .product-warranty .warranty-item span{margin-right:5px;}
}
@media screen and (max-width: 1279px){
  .product-summary-section .product-warranty .warranty-item span{font-size:10px;}
}
@media screen and (max-width: 1280px){
  .product-summary-section .product-warranty .warranty-item img{width:38px;}
}

a {
  color: #000;
}

@media screen and (max-width: 768px) {
  .product-warranty {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
  }

  .product-warranty > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    font-size: 0.9rem;
  }


  img {
    width: 35px;
  }
}
</style>
